import React from 'react';
import { DidomiCopyText, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';

interface LabeledCopyProps {
  className?: string;
  text: string;
  label: string;
  tooltip?: string;
}

export const LabeledCopy = ({ className, text, label, tooltip }: LabeledCopyProps) => {
  const { displaySnackbar } = useSnackbar();

  return (
    <div className={className}>
      <div className="flex gap-xxs items-center mb-xxxs">
        <div className="font-semibold text-body-small">{label}</div>

        {tooltip && (
          <DidomiTooltip content={tooltip} variant="helper" className="leading-[0]">
            <DidomiIcon name="helper-text"></DidomiIcon>
          </DidomiTooltip>
        )}
      </div>

      <DidomiCopyText
        className="w-full"
        text={text}
        truncate
        variation="basic-outline"
        is-icon-persistent="true"
        onTextCopied={() => displaySnackbar('Copied to clipboard', { icon: 'check' })}
      >
        {text}
      </DidomiCopyText>
    </div>
  );
};
