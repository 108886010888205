import React, { useState } from 'react';
import { DidomiButton, DidomiHintbox } from '@didomi/ui-atoms-react';

interface TestConnectionStepProps {
  testUrl: string;
  onPrevClicked: () => void;
  onNextClicked: () => void;
}

export const TestConnectionStep = ({
  testUrl,
  onPrevClicked,
  onNextClicked,
}: TestConnectionStepProps) => {
  const [testState, setTestState] = useState<'test-link' | 'test-link-feedback'>('test-link');

  const openTestUrl = () => {
    window.open(testUrl);
    setTestState('test-link-feedback');
  };

  return (
    <div>
      {testState === 'test-link' && (
        <>
          <p className="mb-4 text-body-small leading-body-small">
            Please click the button below to test your SSO connection. You will shortly be
            redirected to another webpage to confirm if the connection test was successful.
          </p>

          <DidomiButton onClick={openTestUrl} variant="top" icon-right="save-publish" size="small">
            Test Connection
          </DidomiButton>
        </>
      )}

      {testState === 'test-link-feedback' && (
        <>
          <DidomiHintbox
            class="mb-6"
            title-text="If your connection is OK, continue SSO settings."
            header-icon-name="speaker-half"
          >
            Otherwise modify SSO settings and retest your connection.
          </DidomiHintbox>

          <div className="flex gap-4">
            <DidomiButton
              class="w-[200px]"
              size="small"
              variant="secondary"
              onClick={onPrevClicked}
            >
              Back to last step
            </DidomiButton>

            <DidomiButton class="w-[200px]" size="small" onClick={onNextClicked}>
              Continue
            </DidomiButton>
          </div>
        </>
      )}
    </div>
  );
};
