import { useCallback, useState } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { SsoConnection } from '@types';
import { ConnectionFormData } from '@utils';
import { useSsoConnectionsApi } from './useSsoConnectionsApi.hook';

type ApiKeysState = {
  error: unknown;
  ssoConnection: SsoConnection;
  view: 'create' | 'edit';
};

const getErrorMessage = error => error.response?.data?.message || error.message;

export const useSsoConnectionsState = () => {
  const { displaySnackbar } = useSnackbar();
  const { getSsoConnections, createSsoConnection, updateSsoConnection, orgGroupId, orgGroupName } =
    useSsoConnectionsApi();

  const [loadingStatus, setLoadingStatus] = useState<'idle' | 'loading' | 'saving'>('idle');
  const [state, setState] = useState<ApiKeysState>({
    ssoConnection: null,
    error: null,
    view: 'create',
  });

  const loadSsoConnections = useCallback(async () => {
    setLoadingStatus('loading');
    try {
      const res = await getSsoConnections();
      const ssoConnection = res.data[0];
      setState({
        error: null,
        ssoConnection,
        // Show edit view only if domain were added
        view: ssoConnection?.auth0_domain_aliases?.length ? 'edit' : 'create',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      displaySnackbar(errorMessage, { variant: 'error' });
      setState({ error: errorMessage, ssoConnection: null, view: 'create' });
    } finally {
      setLoadingStatus('idle');
    }
  }, [getSsoConnections, displaySnackbar]);

  const saveSsoConnection = useCallback(
    async (formData: ConnectionFormData) => {
      setLoadingStatus('saving');
      try {
        const res = state.ssoConnection
          ? await updateSsoConnection(formData, state.ssoConnection.id)
          : await createSsoConnection(formData);

        displaySnackbar('Settings have been saved', { icon: 'check' });
        setState({ ...state, ssoConnection: res.data });
        return true;
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        displaySnackbar(errorMessage, { variant: 'error' });
        setState({ ...state, error: errorMessage });
        return false;
      } finally {
        setLoadingStatus('idle');
      }
    },
    [createSsoConnection, updateSsoConnection, state, displaySnackbar],
  );

  const changeView = (view: 'create' | 'edit') => setState({ ...state, view });

  return {
    state,
    loadingStatus,
    orgGroupId,
    orgGroupName,
    saveSsoConnection,
    loadSsoConnections,
    changeView,
  };
};
