import React from 'react';
import { DidomiTextInputField, DidomiTextareaField } from '@didomi/ui-atoms-react';
import { Form, Formik } from 'formik';
import { ConnectionFormData, SsoSettingsSchema } from '@utils';
import { DomainsField } from '../DomainsField/DomainsField';
import { FormHelperTooltip } from '../FormHelperTooltip/FormHelperTooltip';
import { RequiredFieldsInfo } from '../RequiredFieldsInfo/RequiredFieldsInfo';

interface SettingsFormProps {
  initialValues: ConnectionFormData;
  disabled?: boolean;
  onSubmit: (values: any) => void;
  children: (props: { isValid: boolean }) => React.ReactNode;
}

export const SettingsForm = ({
  initialValues,
  disabled,
  children,
  onSubmit,
}: SettingsFormProps) => {
  const withDomainField = !!initialValues.domains;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SsoSettingsSchema}
      enableReinitialize
      disabled={disabled}
      onSubmit={onSubmit}
    >
      {({ isValid, values, setFieldValue }) => (
        <Form className="flex flex-col gap-xxs" noValidate>
          <DidomiTextareaField
            name="certificate"
            placeholder="Enter your certificate"
            label="X509 certificate"
            rows={3}
            required
            data-cy="certificate-field"
            disabled={disabled}
          >
            <FormHelperTooltip content="The SAML Signing Certificate from your identity provider in CER base64 format." />
          </DidomiTextareaField>

          <DidomiTextInputField
            name="signInUrl"
            placeholder="Enter Login URL"
            label="Login URL"
            required
            data-cy="login-url-field"
            disabled={disabled}
          >
            <FormHelperTooltip content="Login URL from your identity provider." />
          </DidomiTextInputField>

          <DidomiTextInputField
            name="logoutUrl"
            placeholder="Enter Logout URL"
            label="Logout URL"
            data-cy="logout-url-field"
            disabled={disabled}
          >
            <FormHelperTooltip content="Logout URL from your identity provider. This field could be optional in some identity providers in which case the Login URL will be used instead." />
          </DidomiTextInputField>

          {withDomainField && (
            <DomainsField
              className="mb-m"
              addedDomains={values.domains}
              disabled={disabled}
              onValueChange={v => setFieldValue('domains', v)}
            />
          )}

          <RequiredFieldsInfo />

          {/* CTA as a dynamic children that has access to form state */}
          {children({ isValid: withDomainField ? isValid && !!values.domains?.length : isValid })}
        </Form>
      )}
    </Formik>
  );
};
