import React from 'react';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { Standalone } from '@didomi/utility';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';

// Entry point for Single SPA
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const { bootstrap, mount: mountOriginal, unmount } = lifecycles;

const setStylesForStandalone = () => {
  document.head.insertAdjacentHTML('beforeend', `<style>html{height:100%}</style>`);
  document.body.style.height = '100%';
  document.body.style.overflow = 'auto';
  setTimeout(() => {
    const spaDiv = document.getElementById('single-spa-application:@didomi-spa/sso');
    spaDiv.style.height = '100%';
    spaDiv.style.isolation = 'isolate';
  }, 0);
};

async function mount(opts) {
  // If we run the app as standalone, we init the app
  // eslint-disable-next-line no-undef
  if (CONFIG.standalone) {
    await Standalone.initApp();
    setStylesForStandalone();
  }
  await setUpDidomiUIAtoms('sso');
  mountOriginal(opts);
}

export { bootstrap, mount, unmount };
