import React from 'react';
import { DidomiButton, DidomiHintbox } from '@didomi/ui-atoms-react';

export const CreateSettingsSuccessFeedback = ({ onCtaClicked }) => (
  <>
    <DidomiHintbox
      class="mb-s"
      titleText="Congratulations! Your SSO settings are now completed."
      variant="success"
    >
      You can manage settings at anytime.
    </DidomiHintbox>

    <DidomiButton className="w-[200px]" onClick={onCtaClicked}>
      Manage settings
    </DidomiButton>
  </>
);
