import React from 'react';
import { DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';

export const FormHelperTooltip = ({ content }) => (
  <DidomiTooltip
    slot="description"
    class="block leading-[0] ml-2"
    variant="helper"
    content={content}
  >
    <DidomiIcon name="helper-text"></DidomiIcon>
  </DidomiTooltip>
);
