import { useCallback } from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { SsoConnection } from '@types';
import { ConnectionFormData, formDataToConnection } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const BASE_URL = `${CONFIG.environmentConfig.apiBaseUrl}sso-connections`;

export const useSsoConnectionsApi = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId, organization } = useActiveOrganization();

  const getSsoConnections = useCallback(() => {
    return axiosWithInterceptors.get<SsoConnection[]>(
      `${BASE_URL}?organization_id=${organizationId}`,
    );
  }, [axiosWithInterceptors, organizationId]);

  const createSsoConnection = useCallback(
    (formData: ConnectionFormData) => {
      const payload = { ...formDataToConnection(formData), organization_id: organizationId };
      return axiosWithInterceptors.post<SsoConnection>(BASE_URL, payload);
    },
    [axiosWithInterceptors, organizationId],
  );

  const updateSsoConnection = useCallback(
    (formData: ConnectionFormData, connectionId: string) => {
      const payload = { ...formDataToConnection(formData), organization_id: organizationId };
      return axiosWithInterceptors.patch<SsoConnection>(`${BASE_URL}/${connectionId}`, payload);
    },
    [axiosWithInterceptors, organizationId],
  );

  return {
    orgGroupId: organization.organization_group_id,
    orgGroupName: organization.organization_group_name,
    getSsoConnections,
    createSsoConnection,
    updateSsoConnection,
  };
};
