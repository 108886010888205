import React, { useState } from 'react';
import { DidomiButton, DidomiHintbox } from '@didomi/ui-atoms-react';
import { SsoConnection } from '@types';
import { ConnectionFormData } from '@utils';
import { SamlIdentifiers } from 'components/SamlIdentifiers/SamlIdentifiers';
import { SettingsForm } from 'components/SettingsForm/SettingsForm';

interface EditSettingsProps {
  loadingStatus: 'idle' | 'loading' | 'saving';
  orgGroupId: string;
  orgGroupName: string;
  ssoConnection: SsoConnection;
  onSave: (values: ConnectionFormData) => Promise<boolean>;
}

export const EditSettings = ({
  loadingStatus,
  orgGroupId,
  orgGroupName,
  ssoConnection,
  onSave,
}: EditSettingsProps) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const handleConnectionSave = async (values: ConnectionFormData) => {
    const isSaved = await onSave(values);

    if (isSaved) {
      setIsFormDisabled(true);
    }
  };

  const isSaving = loadingStatus === 'saving';

  return (
    <section className="w-[540px]">
      <section className="mb-s p-s bg-neutral-gray-2 rounded-lg">
        <h3 className="mb-5 font-semibold">Your SSO SAML identifiers</h3>
        <SamlIdentifiers orgGroupId={orgGroupId} />
      </section>

      <DidomiHintbox
        class="mb-s"
        variant="warning"
        iconName="warning"
        titleText={`SSO will be updated for ${orgGroupName} group.`}
      >
        All organizations in the group {orgGroupName} will be impacted.
      </DidomiHintbox>

      <SettingsForm
        disabled={isFormDisabled}
        initialValues={{
          certificate: ssoConnection?.auth0_certificate || '',
          signInUrl: ssoConnection?.auth0_sign_in_endpoint || '',
          logoutUrl: ssoConnection?.auth0_sign_out_endpoint || '',
          domains: ssoConnection?.auth0_domain_aliases || [],
        }}
        onSubmit={handleConnectionSave}
      >
        {({ isValid }) => (
          <>
            {isFormDisabled ? (
              <DidomiButton onClick={() => setIsFormDisabled(false)}>Edit</DidomiButton>
            ) : (
              <div className="flex gap-xs">
                <DidomiButton
                  type="reset"
                  variant="secondary"
                  onClick={() => setIsFormDisabled(true)}
                >
                  Cancel
                </DidomiButton>
                <DidomiButton disabled={!isValid || isSaving} type="submit">
                  Save settings
                </DidomiButton>
              </div>
            )}
          </>
        )}
      </SettingsForm>
    </section>
  );
};
