import React, { useMemo, useState } from 'react';
import { DidomiBaseInput, DidomiIconButton, DidomiChip } from '@didomi/ui-atoms-react';
import { SsoDomainsSchema } from '@utils';
import { FormHelperTooltip } from '../FormHelperTooltip/FormHelperTooltip';

interface DomainsFieldProps {
  className?: string;
  disabled?: boolean;
  addedDomains: string[];
  onValueChange?: (value: string[]) => void;
}

const MAX_NUMBER_OF_DOMAINS = 10;

export const DomainsField = ({
  className,
  disabled,
  addedDomains = [],
  onValueChange,
}: DomainsFieldProps) => {
  const [targetInputValue, setTargetInputValue] = useState('');

  const targetInputError = useMemo(() => {
    try {
      SsoDomainsSchema.validateSync({
        domains: [...addedDomains, targetInputValue].filter(Boolean),
      });
    } catch (e) {
      return e.errors[0];
    }
  }, [targetInputValue, addedDomains]);

  const handleAddDomain = () => {
    if (!targetInputValue || targetInputError) return;

    onValueChange([...addedDomains, targetInputValue]);
    setTargetInputValue('');
  };

  const handleRemoveDomain = (domain: string) => {
    const filteredDomains = addedDomains.filter(d => d !== domain);
    onValueChange(filteredDomains);
  };

  const maxNumberReached = addedDomains.length === MAX_NUMBER_OF_DOMAINS;

  return (
    <div className={className}>
      <DidomiBaseInput
        required
        type="text"
        description="1 domain min, 10 domains max"
        label="Domain(s)"
        placeholder={
          maxNumberReached ? 'You have reached the maximum number of domains' : 'Enter domain URL'
        }
        error={targetInputError}
        value={targetInputValue}
        disabled={disabled || maxNumberReached}
        data-cy="domains-field-input"
        onValueChange={e => setTargetInputValue(e.detail)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault(); // prevent form submission
            handleAddDomain();
          }
        }}
      >
        <DidomiIconButton
          slot="right"
          size="small"
          icon="new-create"
          disabled={!!targetInputError || !targetInputValue || maxNumberReached}
          onClick={handleAddDomain}
        />

        <FormHelperTooltip content="List of email domains to which the SAML authentication will be restricted. Make sure this list is also configured in your identity provider." />
      </DidomiBaseInput>

      {/* List of added domains */}
      {addedDomains.length > 0 && (
        <div className="flex flex-wrap gap-xxs">
          {addedDomains.map(domain => (
            <DidomiChip
              key={domain}
              variation="value"
              label={domain}
              rightIcon={disabled ? '' : 'close-sm'}
              onRightIconClick={() => handleRemoveDomain(domain)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
