import React, { useEffect } from 'react';
import { DidomiSkeleton } from '@didomi/ui-atoms-react';
import { CreateSettings, EditSettings } from '@components';
import { useSsoConnectionsState } from '@hooks';
import { MainLayout } from '@layouts';

export const App = () => {
  const {
    state,
    loadingStatus,
    orgGroupId,
    orgGroupName,
    saveSsoConnection,
    changeView,
    loadSsoConnections,
  } = useSsoConnectionsState();

  useEffect(() => {
    loadSsoConnections();
  }, [loadSsoConnections]);

  return (
    <MainLayout>
      <>
        {/* Loading */}
        {loadingStatus === 'loading' && (
          <DidomiSkeleton class="w-[540px]" isLoading={true}>
            <div className="w-full h-[500px]"></div>
          </DidomiSkeleton>
        )}

        {/* Create View */}
        {loadingStatus !== 'loading' && state.view === 'create' && (
          <CreateSettings
            loadingStatus={loadingStatus}
            orgGroupId={orgGroupId}
            orgGroupName={orgGroupName}
            ssoConnection={state.ssoConnection}
            onSave={saveSsoConnection}
            onSwitchToEdit={() => changeView('edit')}
          />
        )}

        {/* Edit View */}
        {loadingStatus !== 'loading' && state.view === 'edit' && (
          <EditSettings
            loadingStatus={loadingStatus}
            orgGroupId={orgGroupId}
            orgGroupName={orgGroupName}
            ssoConnection={state.ssoConnection}
            onSave={saveSsoConnection}
          />
        )}
      </>
    </MainLayout>
  );
};
