import React, { useMemo, useState } from 'react';
import {
  DidomiButton,
  DidomiHintbox,
  DidomiStepper,
  DidomiStepperStep,
} from '@didomi/ui-atoms-react';
import { SsoConnection } from '@types';
import { ConnectionFormData, getSsoTestUrl } from '@utils';
import { CreateSettingsSuccessFeedback } from 'components/CreateSettingsSuccessFeedback/CreateSettingsSuccessFeedback';
import { DomainsField } from 'components/DomainsField/DomainsField';
import { RequiredFieldsInfo } from 'components/RequiredFieldsInfo/RequiredFieldsInfo';
import { SamlIdentifiers } from 'components/SamlIdentifiers/SamlIdentifiers';
import { SettingsForm } from 'components/SettingsForm/SettingsForm';
import { TestConnectionStep } from 'components/TestConnectionStep/TestConnectionStep';

interface CreateSettingsProps {
  loadingStatus: 'idle' | 'loading' | 'saving';
  orgGroupId: string;
  orgGroupName: string;
  ssoConnection: SsoConnection | null;
  onSave: (values: ConnectionFormData) => Promise<boolean>;
  onSwitchToEdit: () => void;
}

export const CreateSettings = ({
  loadingStatus,
  orgGroupId,
  orgGroupName,
  ssoConnection,
  onSave,
  onSwitchToEdit,
}: CreateSettingsProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [addedDomains, setAddedDomains] = useState<string[]>([]);

  const testUrl = useMemo(() => getSsoTestUrl(orgGroupName), [orgGroupName]);

  const handleConnectionSave = async (values: ConnectionFormData) => {
    const isSaved = await onSave(values);

    if (isSaved) {
      setActiveStepIndex(3);
    }
  };

  const handleAddDomains = async () => {
    const isSaved = await onSave({
      domains: addedDomains,
      certificate: ssoConnection.auth0_certificate,
      signInUrl: ssoConnection.auth0_sign_in_endpoint,
      logoutUrl: ssoConnection.auth0_sign_out_endpoint,
    });

    if (isSaved) {
      setActiveStepIndex(5);
    }
  };

  // Connection is complete if all 5 steps were completed
  const isConnectionComplete = activeStepIndex === 5;

  const isSaving = loadingStatus === 'saving';

  return (
    <section className="w-[540px]">
      {isConnectionComplete === false && (
        <DidomiHintbox
          class="mb-s"
          variant="warning"
          iconName="warning"
          titleText={`SSO will be enabled for ${orgGroupName} group.`}
        >
          All organizations in the group {orgGroupName} will be impacted.
        </DidomiHintbox>
      )}

      <DidomiStepper
        activeStep={activeStepIndex}
        onActiveStepChange={e => setActiveStepIndex(e.detail)}
      >
        {/* Step 1 */}
        <DidomiStepperStep
          headerText="Get your SSO SAML identifiers"
          disableDirectNavigation={isConnectionComplete}
        >
          <p className="mb-xs text-body-small" data-cy="step-1-info">
            Make a copy and use them to configure your identity provider.
          </p>

          <SamlIdentifiers orgGroupId={orgGroupId} />

          <DidomiHintbox class="mb-xs" headerIconName="speaker-half">
            If your URLs have been added, you can continue to finalize your SSO settings.
          </DidomiHintbox>

          <DidomiButton className="w-[200px]" size="small" onClick={() => setActiveStepIndex(1)}>
            Continue
          </DidomiButton>
        </DidomiStepperStep>

        {/* Step 2 */}
        <DidomiStepperStep
          headerText="Configure your identity provider"
          disableDirectNavigation={isConnectionComplete}
        >
          <p className="mb-xs text-body-small" data-cy="step-2-info">
            Go to your identity provider and make sure it&apos;s configured with your SSO SAML
            identifier.
          </p>

          <p className="mb-xs text-body-small">
            You will then get the metadata needed to setup your SSO settings (Step 3 below).
          </p>

          <DidomiButton className="w-[200px]" size="small" onClick={() => setActiveStepIndex(2)}>
            Continue
          </DidomiButton>
        </DidomiStepperStep>

        {/* Step 3 */}
        <DidomiStepperStep
          headerText="Setup SSO settings"
          disableDirectNavigation={isConnectionComplete}
        >
          <p className="mb-xs text-body-small" data-cy="step-3-info">
            Insert the metadata provided by your identity provider to setup SSO settings.
          </p>

          <SettingsForm
            initialValues={{
              certificate: ssoConnection?.auth0_certificate || '',
              signInUrl: ssoConnection?.auth0_sign_in_endpoint || '',
              logoutUrl: ssoConnection?.auth0_sign_out_endpoint || '',
            }}
            onSubmit={handleConnectionSave}
          >
            {({ isValid }) => (
              <DidomiButton
                class="w-[200px]"
                type="submit"
                size="small"
                disabled={!isValid || isSaving}
              >
                Continue
              </DidomiButton>
            )}
          </SettingsForm>
        </DidomiStepperStep>

        {/* Step 4 */}
        <DidomiStepperStep
          headerText="Test SSO connection"
          disableDirectNavigation={isConnectionComplete || !ssoConnection}
        >
          <div data-cy="step-4-info">
            <TestConnectionStep
              key={String(activeStepIndex === 3)} // reset component state when step is changed
              testUrl={testUrl}
              onPrevClicked={() => setActiveStepIndex(2)}
              onNextClicked={() => setActiveStepIndex(4)}
            />
          </div>
        </DidomiStepperStep>

        {/* Step 5 */}
        <DidomiStepperStep
          headerText="Complete SSO settings"
          disableDirectNavigation={isConnectionComplete || !ssoConnection}
        >
          <p className="mb-xs" data-cy="step-5-info">
            Add a list of domains to finalize your SSO settings.
          </p>

          <DomainsField
            className="mb-m"
            addedDomains={addedDomains}
            onValueChange={v => setAddedDomains(v)}
          />

          <RequiredFieldsInfo />

          <DidomiButton
            className="mt-xs"
            disabled={addedDomains.length === 0 || isSaving}
            onClick={handleAddDomains}
          >
            Finalize your SSO settings
          </DidomiButton>
        </DidomiStepperStep>
      </DidomiStepper>

      {isConnectionComplete && <CreateSettingsSuccessFeedback onCtaClicked={onSwitchToEdit} />}
    </section>
  );
};
