import React from 'react';
import { LabeledCopy } from '../LabeledCopy/LabeledCopy';

interface SamlIdentifiersProps {
  orgGroupId: string;
}

export const SamlIdentifiers = ({ orgGroupId }: SamlIdentifiersProps) => {
  const issuer = `${CONFIG.auth.issuer}:${orgGroupId}`;
  const signInUrl = `${CONFIG.auth.url}/login/callback?connection=${orgGroupId}`;
  const logoutUrl = `${CONFIG.auth.url}/logout`;

  return (
    <>
      <LabeledCopy
        className="mb-xs"
        text={issuer}
        label="Configured issuer"
        tooltip="Your identity provider's unique identifier in Didomi console."
      ></LabeledCopy>

      <LabeledCopy
        className="mb-xs"
        text={signInUrl}
        label="Login URL"
        tooltip="Login or Reply URL where user will be redirected after successful login on the identity provider."
      ></LabeledCopy>

      <LabeledCopy
        className="mb-xs"
        text={logoutUrl}
        label="Logout URL"
        tooltip="Where user will be redirected after successful logout on the identity provider."
      ></LabeledCopy>
    </>
  );
};
