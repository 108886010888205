import React from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';

export const RequiredFieldsInfo = () => {
  return (
    <div className="flex items-center gap-2 text-left text-xs italic">
      <DidomiIcon className="text-primary-blue-6" name="required-sm"></DidomiIcon>
      fields are required
    </div>
  );
};
