import * as Yup from 'yup';
import { DOMAIN_PATTERN } from './patterns';

export const SsoSettingsSchema = Yup.object().shape({
  certificate: Yup.string().trim().required('Please enter a certificate.'),
  signInUrl: Yup.string().trim().url('Please enter a valid URL.').required('Please enter a URL.'),
  logoutUrl: Yup.string().trim().url('Please enter a valid URL.'),
});

export const SsoDomainsSchema = Yup.object().shape({
  domains: Yup.array()
    .of(
      Yup.string()
        .trim()
        .matches(DOMAIN_PATTERN, 'Please enter a valid domain.')
        .required('Please add a domain(s).'),
    )
    .test(
      'unique',
      'This domain is already added.',
      values => values.length === new Set(values)?.size,
    )
    .min(1, 'Please add a domain(s).'),
});
