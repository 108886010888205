import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiFeatureHeader, DidomiSmallPush } from '@didomi/ui-atoms-react';

type MainLayoutProps = {
  children: React.ReactChild;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/sso');

  return (
    <section className="p-l pb-20 text-primary-blue-6">
      <DidomiFeatureHeader titleText="Single Sign-on Settings" className="w-full block mb-s">
        <div slot="description">Provide a seamless authentication experience for your users.</div>

        <DidomiSmallPush
          href="https://support.didomi.io/how-to-use-didomis-sso"
          target="_blank"
          slot="actions"
          text="Help Center"
          highlighted-text="Single Sign-on configuration"
          smallIcon="right-on"
        ></DidomiSmallPush>
      </DidomiFeatureHeader>

      <div className="flex justify-between gap-12">
        <div>{children}</div>

        <div className="hidden lg:block shrink-0">
          <img src={`${ASSETS_URL}/assets/settings-figures.svg`} alt="" />
        </div>
      </div>
    </section>
  );
};
